@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package uludi.landing.app.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource

@ExperimentalResourceApi
private object String0 {
  public val error_message: StringResource by 
      lazy { init_error_message() }

  public val error_retry_button_label: StringResource by 
      lazy { init_error_retry_button_label() }

  public val landing_title: StringResource by 
      lazy { init_landing_title() }

  public val retry_button_label: StringResource by 
      lazy { init_retry_button_label() }
}

@ExperimentalResourceApi
internal val Res.string.error_message: StringResource
  get() = String0.error_message

@ExperimentalResourceApi
private fun init_error_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error_message", "error_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.error_retry_button_label: StringResource
  get() = String0.error_retry_button_label

@ExperimentalResourceApi
private fun init_error_retry_button_label(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:error_retry_button_label", "error_retry_button_label",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.landing_title: StringResource
  get() = String0.landing_title

@ExperimentalResourceApi
private fun init_landing_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:landing_title", "landing_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "values-es/strings.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.string.retry_button_label: StringResource
  get() = String0.retry_button_label

@ExperimentalResourceApi
private fun init_retry_button_label(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:retry_button_label", "retry_button_label",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "values/strings.xml"),
    )
)
