package com.uludi.bard.theme

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.text.font.FontFamily
import com.uludi.bard.theme.color.darkScheme
import com.uludi.bard.theme.color.lightScheme
import com.uludi.common.resources.LocalCommonResources
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.Font

@Composable
fun BardTheme(
    darkTheme: Boolean = isSystemInDarkTheme(),
    content: @Composable () -> Unit,
) {
    val colorScheme = when {
        darkTheme -> darkScheme
        else -> lightScheme
    }

    MaterialTheme(
        colorScheme = colorScheme,
    ) {
        content()
    }
}

@OptIn(ExperimentalResourceApi::class)
val FontFamily.Companion.Condensed: FontFamily
    @Composable
    get() = FontFamily(Font(LocalCommonResources.current.fonts.robotoCondensed))

