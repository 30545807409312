package com.uludi.landing.app

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.unit.dp
import com.uludi.bard.components.Spacer
import com.uludi.bard.theme.BardTheme
import com.uludi.bard.theme.Condensed
import com.uludi.common.resources.LocalCommonResources
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalResourceApi::class)
@Composable
fun LandingApp() {
    val uriHandler = LocalUriHandler.current
    BardTheme {
        CompositionLocalProvider(
            LocalCommonResources provides DefaultCommonResources(),
            LocalLandingResources provides DefaultLandingResources(),
        ) {
            val landingResources = LocalLandingResources.current
            val commonResources = LocalCommonResources.current
            Surface {
                Box(Modifier.fillMaxSize(), contentAlignment = Alignment.TopCenter) {
                    Column(
                        Modifier
                            .padding(top = 226.dp),
                        verticalArrangement = Arrangement.spacedBy(63.dp),
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        Text(
                            text = stringResource(landingResources.strings.title),
                            style = MaterialTheme.typography.displayLarge,
                            fontFamily = FontFamily.Condensed,
                        )
                        Image(
                            painterResource(commonResources.drawables.logo),
                            contentDescription = null,
                            modifier = Modifier.height(176.dp),
                            contentScale = ContentScale.FillHeight,
                        )
                        Row {
                            Image(
                                painterResource(landingResources.drawables.googlePlayBadge),
                                contentDescription = null,
                                modifier = Modifier.height(60.dp).clickable {
                                    uriHandler.openUri("https://play.google.com/store/apps/details?id=com.uludi.user")
                                },
                                contentScale = ContentScale.FillHeight,
                            )
                            Spacer.H.M()
                            Image(
                                painterResource(landingResources.drawables.appStoreBadge),
                                contentDescription = null,
                                modifier = Modifier.height(60.dp).clickable {
                                    uriHandler.openUri("https://apps.apple.com/us/app/uludi/id6472042360")
                                },
                                contentScale = ContentScale.FillHeight,
                            )
                        }
                        Spacer(Modifier.weight(1f))
                        Image(
                            painterResource(landingResources.drawables.screenshots),
                            contentDescription = null,
                            modifier = Modifier.height(500.dp),
                            contentScale = ContentScale.FillHeight,
                        )
                    }
                }
            }
        }
    }
}