@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package uludi.landing.app.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi

@ExperimentalResourceApi
private object Drawable0 {
  public val app_store_badge: DrawableResource by 
      lazy { init_app_store_badge() }

  public val google_play_badge: DrawableResource by 
      lazy { init_google_play_badge() }

  public val icon: DrawableResource by 
      lazy { init_icon() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val screenshots: DrawableResource by 
      lazy { init_screenshots() }
}

@ExperimentalResourceApi
internal val Res.drawable.app_store_badge: DrawableResource
  get() = Drawable0.app_store_badge

@ExperimentalResourceApi
private fun init_app_store_badge(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_store_badge",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "drawable-es/app_store_badge.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/app_store_badge.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.google_play_badge: DrawableResource
  get() = Drawable0.google_play_badge

@ExperimentalResourceApi
private fun init_google_play_badge(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_play_badge",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "drawable-es/google_play_badge.xml"),
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/google_play_badge.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.icon: DrawableResource
  get() = Drawable0.icon

@ExperimentalResourceApi
private fun init_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/icon.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.logo: DrawableResource
  get() = Drawable0.logo

@ExperimentalResourceApi
private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/logo.xml"),
    )
)

@ExperimentalResourceApi
internal val Res.drawable.screenshots: DrawableResource
  get() = Drawable0.screenshots

@ExperimentalResourceApi
private fun init_screenshots(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:screenshots",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/screenshots.png"),
    )
)
