package com.uludi.landing.app

import androidx.compose.runtime.compositionLocalOf
import com.uludi.common.resources.CommonResources
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.StringResource
import uludi.landing.app.generated.resources.Res
import uludi.landing.app.generated.resources.RobotoCondensed_VariableFont_wght
import uludi.landing.app.generated.resources.app_store_badge
import uludi.landing.app.generated.resources.error_message
import uludi.landing.app.generated.resources.google_play_badge
import uludi.landing.app.generated.resources.icon
import uludi.landing.app.generated.resources.landing_title
import uludi.landing.app.generated.resources.logo
import uludi.landing.app.generated.resources.retry_button_label
import uludi.landing.app.generated.resources.screenshots

@ExperimentalResourceApi
class DefaultCommonResources : CommonResources {
    override val drawables = object : CommonResources.Drawables {
        override val icon = Res.drawable.icon
        override val logo = Res.drawable.logo
    }
    override val strings = object : CommonResources.Strings {
        override val errorMessage = Res.string.error_message
        override val retryButtonLabel = Res.string.retry_button_label
    }
    override val fonts = object : CommonResources.Fonts {
        override val robotoCondensed = Res.font.RobotoCondensed_VariableFont_wght
    }
}

@ExperimentalResourceApi
class DefaultLandingResources : LandingResources {
    override val strings = object : LandingResources.Strings {
        override val title = Res.string.landing_title
    }
    override val drawables = object : LandingResources.Drawables {
        override val screenshots: DrawableResource = Res.drawable.screenshots
        override val appStoreBadge: DrawableResource = Res.drawable.app_store_badge
        override val googlePlayBadge: DrawableResource = Res.drawable.google_play_badge
    }
}

@ExperimentalResourceApi
val LocalLandingResources = compositionLocalOf<LandingResources> {
    error("No landing resources provided")
}

@ExperimentalResourceApi
interface LandingResources {
    val strings: Strings
    val drawables: Drawables

    @ExperimentalResourceApi
    interface Strings {
        val title: StringResource
    }

    @ExperimentalResourceApi
    interface Drawables {
        val screenshots: DrawableResource
        val appStoreBadge: DrawableResource
        val googlePlayBadge: DrawableResource
    }
}