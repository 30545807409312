@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package uludi.landing.app.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.FontResource

@ExperimentalResourceApi
private object Font0 {
  public val RobotoCondensed_VariableFont_wght: FontResource by 
      lazy { init_RobotoCondensed_VariableFont_wght() }
}

@ExperimentalResourceApi
internal val Res.font.RobotoCondensed_VariableFont_wght: FontResource
  get() = Font0.RobotoCondensed_VariableFont_wght

@ExperimentalResourceApi
private fun init_RobotoCondensed_VariableFont_wght(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:RobotoCondensed_VariableFont_wght",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "font/RobotoCondensed-VariableFont_wght.ttf"),
    )
)
